import React, { FC } from 'react';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { Field, FieldProps } from 'formik';
import { InputText, InputTextProps } from '../../InputText';

interface PhoneInputFieldProps extends InputTextProps {}

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

function NumberFormatCustom(props: NumberFormatCustomProps) {
  const { inputRef, onChange, ...other } = props;

  const onValueChange = (values: NumberFormatValues) =>
    onChange({
      target: {
        name: props.name,
        value: values.value,
      },
    });

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={onValueChange}
      format="+1 (###) ###-####"
      mask=" "
      isNumericString
    />
  );
}

export const PhoneInputField: FC<PhoneInputFieldProps> = ({
  requiredMessage,
  helperText,
  name,
  ...props
}) => {
  const validate = (value: string) =>
    !!requiredMessage && !value ? requiredMessage : '';

  return (
    <Field name={name} validate={validate}>
      {({ field, meta }: FieldProps): JSX.Element => {
        const error = meta.touched && !!meta.error;

        return (
          <InputText
            {...props}
            {...field}
            error={error}
            FormHelperTextProps={{ error }}
            helperText={error ? meta.error : helperText}
            InputLabelProps={{ error }}
            requiredMessage={requiredMessage}
            InputProps={{
              error,
              inputComponent: NumberFormatCustom as any,
              'aria-label': name,
            }}
          />
        );
      }}
    </Field>
  );
};
