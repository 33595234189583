import React, { Component } from 'react';

export interface ErrorBoundaryProps {}
export interface ErrorBoundaryState {
  hasError: boolean;
}

export class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static errorMessage = 'Something went wrong.';

  static getDerivedStateFromError(error: any) {
    console.error(error);
    return { hasError: true };
  }

  componentDidCatch(error: any) {
    if (typeof error === 'object') {
      console.error(
        error.hasOwnProperty('message') ? error.message : JSON.stringify(error)
      );
    } else {
      console.error(error);
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            height: '100%',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <h1>{ErrorBoundary.errorMessage}</h1>
        </div>
      );
    }

    return this.props.children;
  }
}
