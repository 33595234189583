import React, { FC } from 'react';
import {
  TextField,
  TextFieldProps,
  makeStyles,
  InputAdornment,
} from '@material-ui/core';

import { Text } from '../../components/Text';
import { slate } from '../../theme/Colors';

export type InputTextProps = Omit<TextFieldProps, 'required'> & {
  requiredMessage?: string;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
};

const inputBaseStyles = makeStyles((theme) => ({
  input: {
    ...theme.typography.body1,
    color: slate[700],
  },
}));

const inputLabelContainerStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'baseline',
  },
});
const inputLabelStyles = makeStyles({});

export const InputText: FC<InputTextProps> = (props) => {
  const {
    error,
    InputLabelProps,
    InputProps,
    helperText,
    name,
    requiredMessage,
    label,
    FormHelperTextProps,
    startIcon,
    endIcon,
    ...rest
  } = props;

  return (
    <TextField
      {...rest}
      error={error}
      data-testid={name}
      helperText={helperText}
      label={
        label && (
          <div className={inputLabelContainerStyles(props).root}>
            <Text variant="subtitle1">{label}</Text>
            {!requiredMessage && (
              <Text variant="caption" style={{ paddingLeft: '8px' }}>
                (optional)
              </Text>
            )}
          </div>
        )
      }
      id={name}
      InputLabelProps={{
        ...InputLabelProps,
        shrink: true,
        required: false,
        classes: inputLabelStyles(props),
      }}
      InputProps={{
        ...InputProps,
        endAdornment: <InputAdornment position="end">{endIcon}</InputAdornment>,
        startAdornment: (
          <InputAdornment position="start">{startIcon}</InputAdornment>
        ),
        classes: inputBaseStyles(props),
        'aria-label': name,
        disableUnderline: true,
      }}
      name={name}
      required={!!requiredMessage}
      variant="standard"
    />
  );
};
