import React, { ChangeEvent, FC, useState, useEffect } from 'react';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  RadioGroup as MuiRadioGroup,
  RadioGroupProps as MuiRadioGroupProps,
  Radio,
} from '@material-ui/core';

export interface RadioGroupOption {
  label: string;
  value: string;
}

export interface RadioGroupProps extends Omit<MuiRadioGroupProps, 'onChange'> {
  error?: boolean;
  label?: string;
  options?: RadioGroupOption[];
  onChange?: (event: ChangeEvent<HTMLInputElement>) => any;
  requiredMessage?: string;
}

export const RadioGroup: FC<RadioGroupProps> = ({
  error,
  label,
  options,
  children,
  onChange,
  requiredMessage,
  style,
  ...props
}) => {
  const [value, setValue] = useState(props.defaultValue || props.value || '');

  useEffect(() => {
    setValue(props.defaultValue || props.value || '');
  }, [props]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;
    setValue(value);
    onChange && onChange(event);
  };

  const hasError = error || (!!requiredMessage && !value);

  return (
    <FormControl component="fieldset" style={style} error={hasError}>
      {label && (
        <FormLabel component="legend" style={{ marginBottom: '1rem' }}>
          {label}
        </FormLabel>
      )}
      <MuiRadioGroup
        {...props}
        value={value}
        onChange={handleChange}
        aria-label={label || ''}
      >
        {options &&
          options.map((option, index) => (
            <FormControlLabel
              id={option.label}
              value={option.value}
              control={<Radio aria-checked={value === option.value} />}
              label={option.label}
              key={`${index}-${option.value}`}
            />
          ))}
        {children}
      </MuiRadioGroup>
      {hasError && <FormHelperText>{requiredMessage}</FormHelperText>}
    </FormControl>
  );
};
