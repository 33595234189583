import React, { useState, FC } from 'react';

import {
  Alert as MaterialAlert,
  AlertProps as MuiAlertProps,
} from '@material-ui/lab';

export interface AlertProps extends MuiAlertProps {
  isDismissable?: boolean;
  onDismiss?: () => void;
}

export const Alert: FC<AlertProps> = ({
  children,
  onDismiss,
  style,
  ...rest
}) => {
  const [show, setShow] = useState(true);

  const handleDismiss = () => {
    onDismiss && onDismiss();
    setShow(false);
  };

  if (!show) {
    return null;
  }

  return (
    <MaterialAlert
      {...rest}
      variant={'filled'}
      onClose={onDismiss ? handleDismiss : undefined}
      style={{ ...style }}
    >
      {children}
    </MaterialAlert>
  );
};
