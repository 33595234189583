import React, { FC } from 'react';
import * as MuiCore from '@material-ui/core';

export interface CardHeaderProps extends MuiCore.CardHeaderProps {}

export const CardHeader: FC<CardHeaderProps> = ({ children, ...rest }) => {
  return (
    <MuiCore.CardHeader
      titleTypographyProps={{ variant: 'h6' }}
      subheaderTypographyProps={{ variant: 'caption' }}
      {...rest}
    >
      {children}
    </MuiCore.CardHeader>
  );
};
