import React, { FC } from 'react';
import {
  AppBar as MuiAppBar,
  AppBarProps as MuiAppBarProps,
} from '@material-ui/core';

export interface AppBarProps extends MuiAppBarProps {}

export const AppBar: FC<AppBarProps> = ({ children, ...props }) => {
  return <MuiAppBar {...props}>{children}</MuiAppBar>;
};
