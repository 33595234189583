//@ts-ignore
import fetch from 'cross-fetch';
import React, { FC } from 'react';
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  useApolloClient,
  createHttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { MockedProvider } from '@apollo/client/testing';

const uri = process.env.REACT_APP_APPLICATIONS_API_URI
  ? process.env.REACT_APP_APPLICATIONS_API_URI
  : 'http://localhost:4000/graphql';

const httpLink = createHttpLink({ uri });

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('knock_jwtToken');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

export const ApplicationsClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export const ApplicationsProvider: FC = ({ children }) => (
  <ApolloProvider client={ApplicationsClient}>{children}</ApolloProvider>
);

export { useApolloClient, MockedProvider };
