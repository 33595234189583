import React, { FC } from 'react';
import styled from 'styled-components';

import { CircularProgress } from '../CircularProgress';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PageLoader: FC = () => {
  return (
    <Container>
      <CircularProgress size="3rem" />
    </Container>
  );
};
