import { Application } from '../Application';
import { ApplicationFilter } from '../ApplicationFilter';
import { ApplicationSort } from '../ApplicationSort';
import { CreateApplicationInput } from '../CreateApplicationInput';
import { GetApplicationsResponse } from '../GetApplicationsResponse';
import { UpdateApplicationInput } from '../UpdateApplicationInput';
import { ApplicationConfig } from '../ApplicationConfig';
import { UpsertApplicationConfigInput } from '../UpsertApplicationConfigInput';
import { ApplicantPayment } from '../ApplicantPayment';
import { ExchangePaymentTokenInput } from '../ExchangePaymentTokenInput';
import { UserPaymentMethod } from '../UserPaymentMethod';
import { ApplicantLoginToken } from '../ApplicantLoginToken';

export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
};

export type Query = {
  __typename?: 'Query';
  /** Refresh user's access token. */
  refreshAccessToken?: Maybe<Scalars['String']>;
  /** Get Application by Id. */
  getApplication?: Maybe<Application>;
  /** Get applications for leasing teams present in auth token. */
  getApplications?: Maybe<GetApplicationsResponse>;
  /** Get ApplicationApplicant by Id. */
  getApplicationApplicant?: Maybe<ApplicationApplicant>;
  /** Get application configuration for a property */
  getApplicationConfig?: Maybe<ApplicationConfig>;
  /** Get access and refresh tokens */
  verifyLoginToken?: Maybe<ApplicantLoginToken>;
};

export type QueryGetApplicationArgs = {
  applicationId: Scalars['String'];
};

export type QueryGetApplicationsArgs = {
  sorts?: Maybe<Array<ApplicationSort>>;
  filters?: Maybe<Array<ApplicationFilter>>;
  limit?: Maybe<Scalars['Float']>;
  cursor?: Maybe<Scalars['DateTime']>;
};

export type QueryGetApplicationApplicantArgs = {
  applicantId: Scalars['String'];
};

export type QueryGetApplicationConfigArgs = {
  applicantId: Scalars['String'];
  propertyId: Scalars['Int'];
};

export type QueryVerifyLoginTokenArgs = {
  applicationId: Scalars['String'];
};

export type ApplicationApplicant = {
  __typename?: 'ApplicationApplicant';
  id: Scalars['String'];
  authUserId: Scalars['String'];
  applicationId: Scalars['String'];
  type: ApplicationApplicantType;
  email: Scalars['String'];
  metadata: Scalars['JSON'];
  createdTime: Scalars['DateTime'];
  modifiedTime: Scalars['DateTime'];
  deletedTime?: Maybe<Scalars['DateTime']>;
};

/** Application application type constants */
export enum ApplicationApplicantType {
  Primary = 'PRIMARY',
  Guarantor = 'GUARANTOR',
  Cosigner = 'COSIGNER',
}

export type Mutation = {
  __typename?: 'Mutation';
  /** Create new Application. */
  createApplication: Application;
  /** Update existing Application. */
  updateApplication: Scalars['Float'];
  /** Create new ApplicationApplicant. */
  createApplicationApplicant: Application;
  /** Update existing ApplicationApplicant. */
  updateApplicant: ApplicationApplicant;
  /** Soft delete applicant and login token, returns id on success */
  removeApplicant?: Maybe<Scalars['String']>;
  /** Creates or updates an ApplicationConfig */
  upsertApplicationConfig: ApplicationConfig;
  /** Send verification email. */
  sendVerificationEmail?: Maybe<Scalars['String']>;
  /** Exchange Plaid public token for user payment tokens. */
  exchangePaymentTokens: UserPaymentMethod;
  /** Submit a payment using a bank account token. */
  submitPayment: ApplicantPayment;
};

export type MutationCreateApplicationArgs = {
  input: CreateApplicationInput;
};

export type MutationUpdateApplicationArgs = {
  input: UpdateApplicationInput;
  id: Scalars['String'];
};

export type MutationCreateApplicationApplicantArgs = {
  input: CreateApplicationApplicantInput;
};

export type MutationUpdateApplicantArgs = {
  input: UpdateApplicationApplicantInput;
  applicantId: Scalars['String'];
};

export type MutationRemoveApplicantArgs = {
  applicantId: Scalars['String'];
  applicationId: Scalars['String'];
};

export type MutationUpsertApplicationConfigArgs = {
  input: UpsertApplicationConfigInput;
};

export type MutationSendVerificationEmailArgs = {
  applicantId: Scalars['String'];
};

export type MutationExchangePaymentTokensArgs = {
  input: ExchangePaymentTokenInput;
};

export type MutationSubmitPaymentArgs = {
  applicationId: Scalars['String'];
};

export type CreateApplicationApplicantInput = {
  applicationId: Scalars['String'];
  type: ApplicationApplicantType;
  metadata: Scalars['JSON'];
  email: Scalars['String'];
};

export type UpdateApplicationApplicantInput = {
  applicationId?: Maybe<Scalars['String']>;
  type?: Maybe<ApplicationApplicantType>;
  metadata?: Maybe<Scalars['JSON']>;
  email?: Maybe<Scalars['String']>;
};

export type ApplicationApiKey = {
  __typename?: 'ApplicationApiKey';
  id: Scalars['String'];
  clientName: Scalars['String'];
  createdTime: Scalars['DateTime'];
  modifiedTime: Scalars['DateTime'];
  deletedTime?: Maybe<Scalars['DateTime']>;
};

export type LeasingTeamApplicationSettings = {
  __typename?: 'LeasingTeamApplicationSettings';
  id: Scalars['String'];
  knockLeasingTeamId: Scalars['Int'];
  applicationFee: Scalars['Int'];
  createdTime: Scalars['DateTime'];
  modifiedTime: Scalars['DateTime'];
  deletedTime?: Maybe<Scalars['DateTime']>;
};
