import React from 'react';
import { Field, FieldProps } from 'formik';
import NumberFormat from 'react-number-format';
import { InputText, InputTextProps } from '../../InputText';

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

function NumberFormatCustom(props: NumberFormatCustomProps) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format="###-##-####"
      mask="_"
      isNumericString
    />
  );
}

export interface SsnInputFieldProps extends Omit<InputTextProps, 'required'> {
  requiredMessage?: string;
}

export const SsnInputField = ({
  name,
  helperText,
  requiredMessage,
  label,
  ...props
}: SsnInputFieldProps) => {
  const validate = (value: string): string => {
    return !!requiredMessage && (!value || (!!value && value.length < 9))
      ? requiredMessage
      : '';
  };

  return (
    <Field name={name} validate={validate}>
      {({ field, meta }: FieldProps): JSX.Element => {
        const error = meta.touched && !!meta.error;

        return (
          <InputText
            {...props}
            {...field}
            error={error}
            FormHelperTextProps={{ error }}
            helperText={error ? meta.error : helperText}
            InputLabelProps={{ shrink: true, error }}
            id="ssn"
            requiredMessage={requiredMessage}
            InputProps={{
              inputComponent: NumberFormatCustom as any,
              'aria-label': name,
              error,
            }}
            label={label}
            variant="outlined"
          />
        );
      }}
    </Field>
  );
};
