import React, { FC } from 'react';
import {
  Toolbar as MuiToolbar,
  ToolbarProps as MuiToolbarProps,
} from '@material-ui/core';

export interface ToolbarProps extends MuiToolbarProps {}

export const Toolbar: FC<ToolbarProps> = ({ children, ...props }) => {
  return <MuiToolbar {...props}>{children}</MuiToolbar>;
};
